export const R_PRODUCT = '/product';
export const R_PRODUCTS = '/products';
export const R_SHOPPING_CART = '/shopping-cart';
export const R_UNAVAILABLE_ITEM = '/unavailable-item';
export const R_DOCTOR = '/doctor';
export const R_BEAUTY = '/beauty';
export const R_HEALTH_CARE = '/healthcare';
export const R_VENDOR_DASHBOARD = '/vendor-dashboard';
export const R_LAB = '/lab-test';
export const R_PROMO = '/promo';
export const R_MORE = '/account';
export const R_PROFILE = '/my-profile';
export const R_ACCOUNT_MY_PRESCRIPTIONS = '/account/prescriptions';
export const R_ACCOUNT_REQUESTED_PRODUCT = '/account/notified-products';
export const R_NOTIFICATION = '/account/notification';
export const R_MY_ORDER = '/account/orders';
export const R_MY_REVIEW = '/account/reviews';
export const R_MY_OREDER_REVIEW = '/account/my-orders';
export const R_REQ_PRODUCT = '/account/suggest-products';
export const R_MY_FAVORITE = '/account/wishlist';
export const R_MANAGE_ADDRESS = '/account/address';
export const R_MA_ADD_ADDRESS = '/account/address/add-shipping-address';
export const R_PAYMENT_HISTORY = '/account/transaction-history';
export const R_SPECIAL_OFFER = '/account/offers';
export const R_REFER_EARN = '/account/referral';
export const R_HEALTH_TIPS = '/blog';
export const R_PHARMACY_REGISTER = '/pharmacy-register';
export const R_RATE_US = '/account/rate-us';
export const R_EDIT_PROFILE = `${R_PROFILE}/edit-profile`;
export const R_PROCEED_TO_CHECKOUT = `/proceed-to-checkout`;
export const R_PAYMENT = `/payment`;
export const R_BRAND = `/brand`;

// Additional Page
export const R_PRIVACY_POLICY = `/page/privacy`;
export const R_TOS = `/page/tos`;
export const R_RETURN_POLICY = `/page/return-policy`;
export const R_FAQ = `/page/faqs`;
export const R_JOBS = `/jobs`;
